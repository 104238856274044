<template>
	<div class="container">
		<top-header></top-header>
		<div class="message_list width-1300">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/index'}">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/user'}">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/messageList'}">留言列表</el-breadcrumb-item>
				<el-breadcrumb-item>留言详情</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="label">个人中心</div>
			<div class="user_center">
				<div class="top-option">
					<div class="item" @click="gourl('/user')">
						<span>基本设置</span>
					</div>
					<!-- <div class="item" @click="gourl('/cloudData')">
						<span>点云数据</span>
					</div> -->
					<div class="item active" @click="gourl('/messageList')">
						<span>留言</span>
					</div>
				</div>
				<div class="info">
					<div class="message_info">
						<div class="item">
							<p>留言标题：{{messageinfo.title}}</p>
						</div>
						<div class="item">
							<p>留言状态：{{messageinfo.is_read}}</p>
						</div>
						<div class="item">
							<p>留言时间：{{messageinfo.updated_at}}</p>
						</div>
						<div class="item">
							<p>联系方式：{{messageinfo.link_style}}</p>
						</div>
						<div class="item">
							<p>联系号码：{{messageinfo.link_number}}</p>
						</div>
						<div class="item">
							<p>留言内容：{{messageinfo.content}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../../components/header.vue'
	import bottomFooter from '../../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				messageinfo: {}
			}
		},
		created() {
			this.getMessageinfo();
		},
		methods: {
			getMessageinfo() {
				this.$get('front/comment/get_details', {
					id: this.$route.query.id
				}).then(res => {
					this.messageinfo = res.data.data;
				})
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.getMessagelist();
			},
			gourl(url) {
				this.$router.push(url)
			},
			handleDelete(index, row) {
				this.$confirm("是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						this.$post("front/del/comment", {
							id: row.id,
						}).then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.getArticlelist();
							} else {
								this.$notify({
									title: "提示",
									message: res.data.message,
									type: "error",
								});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container{
		background-color: #E8E9ED;
	}
	.message_list {
		margin-top: 60px;
		position: relative;
		padding-top: 20px;
		padding-bottom: 1px;

		@media screen and(min-width:320px) and (max-width:767px) {
			margin-top: 85px;
			min-height: 100vh;
		}

		.label {
			font-size: 26px;
			letter-spacing: 2px;
			margin: 30px 0;
		}
		.user_center{
			background-color: #fff;
			margin-bottom: 50px;
			box-sizing: border-box;
			height: 70.4vh;
			padding-top: 20px;
			.info{
				display: inline-block;
				margin-left: 2%;
				width: 75%;
			}
		}
		.message_info {
			.item {
				border-top: 1px solid #e5e5e5;
				padding:15px;
				p{
					margin:0 0;
				}
				&:last-child{
					border-bottom: 1px solid #e5e5e5;
				}
			}
		}

		.top-option {
			display: inline-block;
			vertical-align: top;
			width: 20%;
			border-right: 1px solid #e5e5e5;
			height: 100%;
			.item {
				padding: 13px 20px;
				font-size: 14px;
				cursor: pointer;
				transition: background-color .3s;
				&:hover {
					background-color: #E5EAF2;
					color: #00337D;
					border-right: 3px solid #00337D;
				}
			}
		
			.active {
				background-color: #E5EAF2;
				color: #00337D;
				font-weight: 800;
				border-right: 3px solid #00337D;
			}
		}
	}
</style>
